type Item = {
  url: string
  title: string
}

export type NavigationContent = {
  home: Item
  collection: Item
  collectionDetail: Item
  collectionProductDetail: Item
  separateItems: Item
  separateItemsDetail: Item
  separateItemsProductDetail: Item
  looks: Item
  retailers: Item
  contact: Item
  newCollection: Item
  rings: Item
  bracelets: Item
  necklaces: Item
  earrings: Item
  gemstones: Item
  frames: Item
  bases: Item
  giftcard: Item
  "new-collection": Item
  contentPage: Item
  cart: Item
  search: Item
  checkout: Item
  checkoutStep: Item
  checkoutDetails: Item
  checkoutPaymentMethod: Item
  checkoutSummary: Item
  checkoutPayment: Item
  checkoutThanks: Item
  privacyStatement: Item
  termsAndConditions: Item
  return: Item
  ringSize: Item
  warranty: Item
  newsletter: Item
  returnPolicy: Item
  deliveryInformation: Item
  womenVote2023: Item
}

type Navigation = {
  [locale: string]: NavigationContent
}

export const navigation: Navigation = {
  nl: {
    home: {
      title: "Home",
      url: "/",
    },
    collection: {
      title: "Collectie",
      url: "/collectie",
    },
    collectionDetail: {
      title: "",
      url: "/collectie/[uid]",
    },
    collectionProductDetail: {
      title: "",
      url: "/collectie/[uid]/[pid]",
    },
    separateItems: {
      title: "Losse items",
      url: "/collectie/losse-items",
    },
    separateItemsDetail: {
      title: "",
      url: "/collectie/losse-items/[uid]",
    },
    separateItemsProductDetail: {
      title: "",
      url: "/collectie/losse-items/[uid]/[pid]",
    },
    looks: {
      title: "Looks",
      url: "/looks",
    },
    retailers: {
      title: "Retailers",
      url: "/retailers",
    },
    contact: {
      title: "Contact",
      url: "/contact",
    },
    newCollection: {
      title: "Nieuwe collectie",
      url: "/collectie/nieuwe-collectie",
    },
    rings: { title: "Ringen", url: "/collectie/ringen" },
    bracelets: { title: "Armbanden", url: "/collectie/armbanden" },
    necklaces: { title: "Colliers", url: "/collectie/colliers" },
    earrings: { title: "Oorbellen", url: "/collectie/oorbellen" },
    gemstones: { title: "Stenen", url: "/collectie/losse-items/stenen" },
    frames: { title: "Frames", url: "/collectie/losse-items/frames" },
    bases: { title: "Bases", url: "/collectie/losse-items/bases" },
    giftcard: {
      title: "Choices by DL Giftcard",
      url: "/collectie/losse-items/giftcard",
    },
    "new-collection": {
      title: "Nieuwe collectie",
      url: "/collectie/nieuwe-collectie",
    },
    contentPage: { title: "Pagina", url: "/p" },
    cart: { title: "Winkelmand", url: "/winkelmand" },
    search: { title: "Zoeken", url: "/zoeken" },
    checkout: { title: "Bestellen", url: "/bestellen" },
    checkoutStep: { title: "Bestellen", url: "/bestellen/[step]" },
    checkoutDetails: { title: "Details", url: "/bestellen/details" },
    checkoutPaymentMethod: {
      title: "Betaalwijze",
      url: "/bestellen/betaalwijze",
    },
    checkoutSummary: { title: "Overzicht", url: "/bestellen/overzicht" },
    checkoutPayment: { title: "Betalen", url: "/bestellen/betalen" },
    checkoutThanks: { title: "Bedankt", url: "/bestellen/bedankt" },
    privacyStatement: {
      title: "Privacyverklaring",
      url: "/p/privacy-verklaring",
    },
    termsAndConditions: {
      title: "Algemene Voorwaarden",
      url: "/p/algemene-voorwaarden",
    },
    return: { title: "Retourneren", url: "/retourneren" },
    ringSize: {
      title: "Ringmaat",
      url: "/static/ringmaten.pdf",
    },
    returnPolicy: {
      title: "Retourbeleid",
      url: "/p/retour-beleid",
    },
    warranty: {
      title: "Garantie",
      url: "/garantie",
    },
    newsletter: {
      title: "Nieuwsbrief",
      url: "/nieuwsbrief",
    },
    deliveryInformation: {
      title: "Bezorginformatie",
      url: "/p/bezorg-informatie",
    },
    womenVote2023: {
      title: "Ondernemende vrouw 2023",
      url: "/ondernemende-vrouw2023",
    },
  },
  en: {
    home: {
      title: "Home",
      url: "/",
    },
    collection: {
      title: "Collection",
      url: "/collection",
    },
    collectionDetail: {
      title: "Collection",
      url: "/collection/[uid]",
    },
    collectionProductDetail: {
      title: "Collection",
      url: "/collection/[uid]/[pid]",
    },
    separateItems: {
      title: "Separate Items",
      url: "/collection/separate-items",
    },
    separateItemsDetail: {
      title: "",
      url: "/collection/separate-items/[uid]",
    },
    separateItemsProductDetail: {
      title: "",
      url: "/collection/separate-items/[uid]/[pid]",
    },
    looks: {
      title: "Looks",
      url: "/looks",
    },
    retailers: {
      title: "Retailers",
      url: "/retailers",
    },
    contact: {
      title: "Contact",
      url: "/contact",
    },
    newCollection: {
      title: "New collection",
      url: "/collection/new-collection",
    },
    rings: { title: "Rings", url: "/collection/rings" },
    bracelets: { title: "Bracelets", url: "/collection/bracelets" },
    necklaces: { title: "Necklaces", url: "/collection/necklaces" },
    earrings: { title: "Earrings", url: "/collection/earrings" },
    gemstones: {
      title: "Gemstones",
      url: "/collection/separate-items/gemstones",
    },
    frames: { title: "Frames", url: "/collection/separate-items/frames" },
    bases: { title: "Bases", url: "/collection/separate-items/bases" },
    giftcard: {
      title: "Choices by DL Giftcard",
      url: "/collection/separate-items/giftcard",
    },
    "new-collection": {
      title: "New collection",
      url: "/collection/new-collection",
    },
    contentPage: { title: "Page", url: "/p" },
    cart: { title: "Shopping bag", url: "/shopping-bag" },
    search: { title: "Search", url: "/search" },
    checkout: { title: "Checkout", url: "/checkout" },
    checkoutStep: { title: "Checkout", url: "/checkout/[step]" },
    checkoutDetails: { title: "Details", url: "/checkout/details" },
    checkoutPaymentMethod: {
      title: "Payment",
      url: "/checkout/payment-method",
    },
    checkoutSummary: { title: "Summary", url: "/checkout/summary" },
    checkoutPayment: { title: "Pay", url: "/checkout/payment" },
    checkoutThanks: { title: "Thanks", url: "/checkout/thanks" },
    privacyStatement: {
      title: "Privacy statement",
      url: "/p/privacy-statement",
    },
    termsAndConditions: {
      title: "Terms & Conditions",
      url: "/p/terms-and-conditions",
    },
    return: { title: "Return", url: "/return" },
    ringSize: {
      title: "Ring size",
      url: "/static/ringmaten.pdf",
    },
    returnPolicy: {
      title: "Return policy",
      url: "/p/return-policy",
    },
    warranty: {
      title: "Warranty",
      url: "/warranty",
    },
    newsletter: {
      title: "Newsletter",
      url: "/newsletter",
    },
    deliveryInformation: {
      title: "Delivery information",
      url: "/p/delivery-information",
    },
    womenVote2023: {
      title: "Enterprising women 2023",
      url: "/enterprising-women2023",
    },
  },
}
